<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="8" xl="9" class="pa-10 overflow-auto">
        <!-- BUSCAR CLIENTE -->
        <v-row
          align="center"
          justify="center"
          class="mt-12"
          style="min-height: 350px"
        >
          <v-col cols="12" md="10" lg="9" xl="6">
            <Finduser color="#DDECF7" />
          </v-col>
        </v-row>

        <!-- CARACTERISTICAS DEL CANAL -->
        <!-- <ChannelFeatures
          :generalInfo="generalInfoData"
          :advanceInfo="advanceInfoData"
        /> -->

        <v-row>
          <v-col cols="12" md="6">
            <!-- COMPANIAS DEL CANAL -->
            <!-- <ChannelCompanies :items="generalInfoData?.companias" /> -->
          </v-col>
          <v-col>
            <!-- <ChannelPayments :items="generalInfoData?.medios_de_pago" /> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="showPendingDocs || showPendingQuotes"
        cols="12"
        md="4"
        xl="3"
        class="flex-grow-1 overflow-auto global-aside-height"
      >
        <div class="d-flex flex-column h-100">
          <!-- DOCUMENTOS PENDIENTES -->
          <keep-alive>
            <StatusBar
              ref="statusBar"
              :show-docs="showPendingDocs"
              :show-quotes="showPendingQuotes"
            />
          </keep-alive>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Finduser from "@/components/User-Components/HomeStart/FindUser.vue";
import StatusBar from "@/components/Pages-Components/Dashboard/StatusBar";
// import ChannelFeatures from "@/components/Pages-Components/Dashboard/ChannelFeatures.vue";
// import ChannelCompanies from "@/components/Pages-Components/Dashboard/Companies.vue";
// import ChannelPayments from "@/components/Pages-Components/Dashboard/PaymentMethods.vue";
export default {
  name: "PanelDeControl",
  components: {
    Finduser,
    StatusBar,
    // ChannelFeatures,
    // ChannelCompanies,
    // ChannelPayments,
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    lastSession() {
      return this.$store.getters.lastSession;
    },

    generalInfoData() {
      return this.$store.getters.getGeneralInfo;
    },
    advanceInfoData() {
      return this.$store.getters.getAdvanceInfo;
    },

    // ACCESO A PANTALLAS
    accesoPantallas() {
      return this.generalInfoData?.personalizacion_corporativa
        ?.acceso_pantallas;
    },
    showPendingDocs() {
      return (
        this.accesoPantallas?.find(
          (val) =>
            val.codigo === "DOCUMENTOS_PENDIENTES" &&
            val.seccion === "PANTALLA_DASHBOARD"
        )?.activo ?? true
      );
    },
    showPendingQuotes() {
      return (
        this.accesoPantallas?.find(
          (val) =>
            val.codigo === "COTIZACIONES_PENDIENTES" &&
            val.seccion === "PANTALLA_DASHBOARD"
        )?.activo ?? true
      );
    },
  },
  methods: {
    // ...mapActions("Proposal", ["getLocations"]),
    Reload() {
      location.reload();
    },
  },
};
</script>
