<template>
  <div>
    <v-card flat class="invisible">
      <div class="d-flex align-center mt-10">
        <span class="material-symbols-rounded"> folder_open </span>
        <span class="bold h5 ml-3">Documentos</span>
      </div>

      <div>
        <div v-if="loading">
          <v-skeleton-loader
            v-for="index in 3"
            :key="index"
            class="rounded-lg mb-2 mt-4"
            type="card"
            max-height="170px"
          >
          </v-skeleton-loader>
        </div>
        <div v-else-if="!Documentos.length > 0" class="pr-4 pl-4">
          <v-img src="@/assets/ilustracion_no_documentos__Artboard.png" />
          <h3 class="global-pendentText text-center">
            No hay documentos pendientes
          </h3>
        </div>
        <div v-else>
          <div
            v-for="(docs, i) in Documentos"
            :key="`docs${i}`"
            to="/documentos"
          >
            <PendingCard :loading="docs.loading" height="220">
              <template v-slot:number>
                <p>Nº Propuesta {{ docs.numero_propuesta }}</p>
              </template>
              <template v-slot:name>
                <p>{{ docs.nombre_usuario }}</p>
              </template>
              <template v-slot:description></template>
              <template v-slot:rut>
                {{
                  (docs.numero_documento ||
                    docs.numero_documento_cliente ||
                    "RUT --") | rutFormat
                }}
              </template>
              <template v-slot:detail>
                <div class="d-flex flex-column">
                  <p class="font-weight-bold title-color">
                    {{
                      docs.estado_descripcion ||
                      docs.estado_general_descripcion ||
                      "Desconocido"
                    }}
                  </p>
                  <v-row no-gutters class="grey-lighten-5">
                    <v-col
                      cols="6"
                      v-for="document in docs.documentos"
                      :key="document.fecha_creacion"
                    >
                      <p class="caption">
                        {{ document.documento_descripcion }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-slot:button>
                <v-btn
                  @click="onPress(docs)"
                  rounded
                  block
                  color="primary"
                  class="text-none mt-2"
                  :loading="docs.loading"
                >
                  Adjuntar documentos
                </v-btn>
              </template>
            </PendingCard>
          </div>

          <p class="text-center mt-5">
            <v-btn
              color="primary"
              class="text-none"
              text
              @click="$router.push({ name: 'documents' })"
            >
              Ver más documentos <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PendingCard from "@/components/Pages-Components/Dashboard/SideBarCards.vue";

export default {
  name: "PendingDocuments",
  props: {
    loading: {
      type: Boolean,
      default: false,
      description: "Cargando documentos.",
    },
  },
  components: {
    PendingCard,
  },
  methods: {
    onPress(item) {
      item.loading = true;
      this.$emit("action-button", item);
    },
  },
  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    ...mapGetters("Documents", ["Documentos"]),
  },
};
</script>
